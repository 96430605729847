/**
 * @author Beka Chkhaidze
 */

import { customRef } from "vue";
import type { SecondaryDataInterface } from "../../types/ContentType";

import { useMainStore } from "@/store/main/index";

export const hasConfig = (
    contentData: SecondaryDataInterface | undefined,
    configKey: string
) => contentData?.conf?.conf?.includes(configKey);

/**
 * @summary automatically triger scroll update, when value changes
 *
 */
export function useScrollTriggerableRef<T>(value: T, delay: number = 500) {
    const mainStore = useMainStore();

    return customRef((track, trigger) => {
        return {
            get() {
                track();
                return value;
            },
            set(newValue) {
                value = newValue;
                trigger();
            },
        };
    });
}

export const getRandomId = () => {
    return (Math.random() + 1).toString(36).substring(7);
};

export const randInt = (min: number, max: number): number =>
    Math.floor(Math.random() * (max - min + 1)) + min;

export const formatNumber = (number: string | number) => {
    const strNumber = number.toString();

    if (strNumber?.toLowerCase() === "infinity") {
        console.error(
            "\n\nInvalid number, got 'Infinity'. please check formula\n\n"
        );
        return Infinity;
    }

    const chars = strNumber.split("");
    const decimalIndex = chars.indexOf(".");
    const decimalPos = decimalIndex !== -1 ? decimalIndex : chars.length;

    for (let i = decimalPos - 3; i > 0; i -= 3) {
        chars.splice(i, 0, ",");
    }

    const readableNumber = chars.join("");

    return readableNumber;
};

/**
 * experimental utility, wanted to be used instead of scroll event for better performance
 */

// interface InViewProps {
//     threshold: number;
//     target: HTMLDivElement;
// }
// export const intersect = (props: InViewProps) => {
//     const { threshold, target } = props;

//     const observerHanlder = (entries: IntersectionObserverEntry[]) => {
//         entries.forEach((entry) => {
//             const intersecting = entry.isIntersecting;
//             const target = entry.target as HTMLDivElement;

//
//             // observer.unobserve(entry.target);
//         });
//     };

//     const observer = new IntersectionObserver(observerHanlder, {
//         threshold,
//     });

//     observer.observe(target);
// };

/**
 * @summary: observer class addition to element
 * used to catch when element enters viewport
 */
type DomObserverCallback = (
    mutationList: MutationRecord[],
    observer: MutationObserver
) => void;
export const observeClassAddition = (
    target: HTMLElement,
    className: string,
    callback: DomObserverCallback
): MutationObserver => {
    const observer = new MutationObserver((mutationList, observer) => {
        for (const mutation of mutationList) {
            if (
                mutation.type === "attributes" &&
                mutation.attributeName === "class"
            ) {
                const classList = target.classList;
                if (classList.contains(className)) {
                    callback(mutationList, observer);
                }
            }
        }
    });

    observer.observe(target, { attributes: true });

    return observer;
};

export const humanReadableNum = (number: number) => {
    if (!number) return;

    const strNumber = number.toString();
    const chars = strNumber.split("");

    chars.reverse();

    for (let i = 3; i < chars.length; i += 4) {
        chars.splice(i, 0, " ");
    }

    const readableNumber = chars.reverse().join("");

    return readableNumber;
};

export const selectFormatter = (
    title: string | number,
    value: string | number = title
) => {
    title = title?.toString();
    value = value?.toString();

    return {
        title,
        value,
    };
};
